import * as Counter from 'counter';
import * as Words from 'words';
import * as Library from '../library';

export function init(){

	Library.checkIfTouchDevice();
	Counter.init();
	Words.init();
	
	// We could add preload to the head, but would rather we download Firebase assets first
	preloadIcons();

}

function preloadIcons(){
	// In case we're on another domain, get the current domain
	const domain = window.location.origin;
	const icons = document.querySelectorAll('.word[data-icon]');

	icons.forEach(icon => {
		const image = new Image();
		image.src = domain + '/assets/img/icons/' + icon.dataset.icon + '.svg';
	});
}