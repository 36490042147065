import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set, increment} from "firebase/database";
import * as Words from 'words';
import * as Library from '../library';


let loadTimer;
let localOnly = false;

let totalClicks = 0;
let firstRun = true;
let firebaseRunning = false;

export function init() {

	setupFirebase();
	syncClicks();
	// The typing of 'clicks' gets us a couple of seconds on page load while Firebase loads
	typeClicks(true);


}

export function setupFirebase(){

	// Update to env variables
	const firebaseConfig = {
		apiKey: "AIzaSyC314Ja-TdgAhVhrl3g-R1Ef-iyZtqvVnQ",
		authDomain: "lfe-website-4c4ba.firebaseapp.com",
		databaseURL: "https://lfe-website-4c4ba-default-rtdb.firebaseio.com",
		projectId: "lfe-website-4c4ba",
		storageBucket: "lfe-website-4c4ba.appspot.com",
		messagingSenderId: "1043973716602",
		appId: "1:1043973716602:web:a92e6d558660a7bf0e99c3"
	};
	
	// Go Firebase
	const app = initializeApp(firebaseConfig);
	
	// AppCheck domain verification
	const appCheck = initializeAppCheck(app, {
		provider: new ReCaptchaV3Provider("6Ld-umAjAAAAAHG95v6MXjYugJN5mLLE0D7z3uDo"),
		isTokenAutoRefreshEnabled: true
	});

}

export function setLoadTimeout(){
	loadTimer = setTimeout(() => {
		// If the counter hasn't loaded, load it manually, set it to 0
		if(!firebaseRunning){
			localOnly = true;
			initCounter(totalClicks);
		}
	}, 3000);
}

export function syncClicks(){
	// Set a timeout to load the counter manually if it doesn't load
	setLoadTimeout();

	const db = getDatabase();
	const clicks = ref(db, 'clicks');
	onValue(clicks, (snapshot) => {

		clearTimeout(loadTimer);

		// Double check we shouldn't be running in local-only mode, in case the value changes later and accelerates the counter
		if(!localOnly){
			const dbClicks = parseInt(snapshot.val());

			// Skip the first run update, as this is just pulling the value
			if(firstRun){
				firebaseRunning = true;
				totalClicks = dbClicks;
				initCounter(dbClicks);
			// Investigate – returning 1 after db increment
			} else if(dbClicks > 1 && !firstRun){
				// If it's greater than local total, update it as it's remote
				if(dbClicks > totalClicks){
					totalClicks = dbClicks;
					updateCounter(dbClicks, true);
				}
			}
		}

		firstRun = false;

	});
}

export function setClicks(clicks){

	// Assuming the server update will be OK, update the local total immediately
	totalClicks = totalClicks + 1;
	updateCounter(totalClicks, false);

	if(!localOnly){
		const db = getDatabase();
		set(ref(db), {
			// Increment the clicks on the server, not local
			clicks: increment(1)
		});
	}
}

export function updateCounter(totalClicks, remote){
	const counter = document.querySelector('.counter-total');
	const counterText = document.querySelector('.counter-text');

	// If we're starting from zero, change the text to 'Click'
	if(totalClicks === 1){
		counterText.textContent = 'Click';
	} else if(totalClicks === 2){
		counterText.textContent = 'Clicks';
	}

	counter.textContent = totalClicks;
	
	showBlinker(remote);

}

export function showBlinker(remote){
	const counterBlinker = document.querySelector('.counter-blinker');
	const blinkerType = remote ? 'counter-blinker--remote' :  'counter-blinker--local';

	counterBlinker.classList.remove('counter-blinker--remote', 'counter-blinker--local');
	counterBlinker.classList.add(blinkerType);
	counterBlinker.classList.add('counter-blinker--active');
	// If remote, show the blinker for 2 seconds
	if(remote){
		setTimeout(() => {
			counterBlinker.classList.remove('counter-blinker--active');
		}, 2000);
	}
}

export function hideBlinker(){
	const counterBlinker = document.querySelector('.counter-blinker');
	counterBlinker.classList.remove('counter-blinker--remote', 'counter-blinker--local', 'counter-blinker--initial');
	counterBlinker.classList.remove('counter-blinker--active');
}

export function updateCounterPosition() {
	const textHeight = document.querySelector('.text-container').offsetHeight;
	document.querySelector('.counter-container').style.top = textHeight + 'px';
}

export function initCounter(totalClicks){
	
	showBlinker();

	let counter = document.querySelector('.counter-total');
	let currentNum = 0;
	// Normalise the click counter so it never takes more than 3 seconds to update
	let numMultiplier = Math.ceil(totalClicks/500);

	let updateCount = () => {
		currentNum = currentNum + numMultiplier > totalClicks ? totalClicks : currentNum + numMultiplier;

		if (currentNum < totalClicks) {
			counter.textContent = currentNum;
			window.setTimeout(updateCount, 5);
		} else {
			counter.textContent = currentNum;
			startPing();
			Words.typeWords(document.querySelectorAll('.word[data-state="1"]'));
		}
	}

	updateCount();

}

export function typeClicks(firstRun){
	// Type out the 'clicks' text
	if(firstRun){
		setTimeout(() => {
			typeClicks();
		}, 500);
	} else {
		const counterText = document.querySelector('.counter-text');		
		if(counterText.textContent === ''){
			setTimeout(() => {
				counterText.textContent = 'C';
				typeClicks();
			}, 120);
		} else if(counterText.textContent === 'C'){
			setTimeout(() => {
				counterText.textContent = 'Cl';
				typeClicks();
			}, 120);
		} else if(counterText.textContent === 'Cl'){
			setTimeout(() => {
				counterText.textContent = 'Cli';
				typeClicks();
			}, 120);
		} else if(counterText.textContent === 'Cli'){
			setTimeout(() => {
				counterText.textContent = 'Clic';
				typeClicks();
			}, 180);
		} else if(counterText.textContent === 'Clic'){
			setTimeout(() => {
				counterText.textContent = 'Click';
				typeClicks();
			}, 240);
		} else if(counterText.textContent === 'Click'){
			setTimeout(() => {
				counterText.textContent = 'Clicks';
			}, 320);
		}
	}
}

export function startPing(){
	// A green ping every 5 seconds
	setInterval(() => {
		const counterBlinker  = document.querySelector('.counter-blinker');
		counterBlinker.classList.add('counter-blinker--ping');
		setTimeout(() => {
			counterBlinker.classList.remove('counter-blinker--ping');
		}, 250);
	}, 5000);
}