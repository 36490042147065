export function checkIfTouchDevice(){
	if(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) ||
	(navigator.msMaxTouchPoints > 0)){
		document.body.classList.add('is-touch');
	}
}


export function isMobile(width = 768){
	if(window.innerWidth <= width){
		return true;
	}
}
