import {fadeIn} from "../library";
import * as Counter from 'counter';

export function init() {
	
	setWordEvents();

}

export function setWordEvents(){
	let hoverTimer = null;
	const words = document.querySelectorAll('.word');

	document.querySelectorAll('button.word').forEach(word => {

		word.addEventListener('click', () => clickWord(word));

		// This dims all items that aren't part of this text on hover but is disabled for mobile
		// word.addEventListener('mouseenter', (e) => {
		// 	const link = word.dataset.link;
		// 	// If the word has been clicked, show the hover state for the sentence
		// 	if(word.classList.contains('clicked')){
		// 		hoverTimer = setTimeout(function(){
					
		// 				document.querySelectorAll('.word').forEach(el => {
		// 					if(el.dataset.state !== link && el.dataset.link !== link){
		// 						el.classList.add('dim');
		// 					} else {
		// 						el.classList.add('focus');
		// 					}
		// 				});
					
		// 		}, 1200);
		// 	}
		// });

		// word.addEventListener('mouseleave', (e) => {
		// 	clearTimeout(hoverTimer);
		// 	document.querySelectorAll('.word').forEach(el => {
		// 		el.classList.remove('dim');
		// 		el.classList.remove('focus');
		// 	});
		// });

	});

}



export function clickWord(clickedWord){
	const link = clickedWord.dataset.link;

	if(!clickedWord.classList.contains('clicked')){
		// If it's not been clicked yet
		// Remove retyped class in case it's been retyped - the retype CSS animation otherwise overrides the clicked animation
		clickedWord.classList.remove('retyped');
		clickedWord.classList.add('clicked');
		typeWords(document.querySelectorAll('.word[data-state="' + link + '"]'), false, clickedWord);
		Counter.setClicks();
	} else {
		// If we've clicked this already, retype the words
		typeWords(document.querySelectorAll('.word[data-state="' + link + '"]'), true, clickedWord);
	}
	
}


export function typeWords(words, retype, element){
	let interval = 0;
	let totalWords = words.length - 1;

	if(!retype){
		// Only show blinker when not retyping
		Counter.showBlinker();
	} else {
		element.classList.remove('retyped');
		setTimeout(() => {
			// Tiny delay to ensure the animation runs
			element.classList.add('retyped');
		}, 100);
	}

	for (const [i, word] of words.entries()){

		interval = interval + 120;

		// Set some easing for the last few words
		if(i === totalWords - 2){
			interval = interval + 40;
		} else if(i === totalWords - 1){
			interval = interval + 120;
		} else if(i === totalWords){
			interval = interval + 200;
		}

		setTimeout(() => {
			
			if(retype){
				word.classList.remove('retyped');
				setTimeout(() => {
					// Tiny delay to ensure the animation runs
					word.classList.add('retyped');
				}, 60);
			} else {
				word.classList.add('typed');

				// Scroll to center the clicked word, as long as an element has been clicked, not first run
				if(element){
					updateScrollPosition(word);
				}
			}

			// If it's the last word, hide the blinker
			if(i === totalWords){
				Counter.hideBlinker();
			}
			
			Counter.updateCounterPosition();

		}, interval);

	}

}

export function updateScrollPosition(word){
	const winHeight = window.innerHeight;
	const wordPosition = word.getBoundingClientRect();
	const containerPadding = parseInt(window.getComputedStyle(document.querySelector('.text-container')).paddingBottom);
	const containerBottom = winHeight - containerPadding;
	const newScrollPosition = (word.offsetTop - winHeight + wordPosition.height + containerPadding);
	
	// If the word is below the bottom of the container, scroll to it
	if(wordPosition.bottom >= containerBottom){
		window.scrollTo({
			top: newScrollPosition,
			left: 0,
			behavior: 'smooth'
		});
	}
}
